// Custom vars
@import "_survey-monkey.scss";
// BREAKPOINTS
$screen-lg: 1200px;
$screen-md: 1024px;
$screen-sm: 990px;
$screen-xs: 767px;
$screen-xxs: 320px;


$font-base: 'Univers-Regular', Arial, Sans-Serif;
$font-cond: 'Univers-Condensed', Arial, Sans-Serif;
$font-cond-light: 'Univers-CondensedLight', Arial, Sans-Serif;
$font-cond-bold: 'Univers-CondensedBold', Arial, Sans-Serif;
$font-arial: Arial, Sans-Serif;

// COLORS
$brand-black: #000;
$brand-white: #fff;
$brand-dark-grey: #392F2C;
$brand-medium-grey: #D8D6D6;
$brand-light-grey: #B2B3B2;
$brand-red: #F7323F;

.d-inline-block {
    display: inline-block;
}

.pr-0 {
    padding-right: 0 !important;
}

.mt-25 {
    margin-top: 25px;
}

.mb-40 {
    margin-bottom: 40px;
}

body {
    margin-bottom: 0 !important;
    font-family: 'Univers-Regular', sans-serif;
    @media (max-width: $screen-sm) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.banners.row.hidden-xs {
    display: none;
}

.gray-bg {
    background: #ECECEC;
}

.employers .footer h6 {
    font-family: $font-cond-bold !important;

}


.footer-navigation {
    li {
        font-size: 14px;
        font-family: $font-arial;
    }
}

.finalize-request {
    margin-bottom: 70px;
}

.d-none,
.hidden {
    display: none;
}

.input-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.btn {
    max-width: 120px;
    border: 1px solid #B2B3B2;
}

.auto-suggest {
    ::placeholder {
        color: #E0E0E5;
    }
}

.postlogin-employer-footer {
    position: relative !important;
    height: auto;
}

.prelogin-employer-footer {
    position: relative !important;
    height: auto;
    
}

.validate-form {
    &__email {
        @media (max-width: 767px) {
            margin-left: 0 !important;
        }
    }
}

.accept_terms {
    &__input {
    position: absolute;
    opacity: 0;
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      padding-left: 30px !important;
      color: #B2B3B2 !important;
      font-size: 14px !important;
      font-family: $font-base;
        a {
            color: #B2B3B2;
            text-decoration: underline;
        }
    }
    & + label {
        &:before {
            cursor: pointer;
            border: 1px solid #B2B3B2;
            border-radius: 2px;
            content: '';
            margin-right: 10px;
            position: absolute;
            left: 0;
            top: 2px;
            vertical-align: text-top;
            width: 15px;
            height: 15px;
        }
    }
    &:hover + label:before {
      background: #fff;
      border: 1px solid #392F2C;
    }
    &:checked + label:before {
      background: #392F2C;
    }
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 3px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
}

.register__container {
    .accept_terms {
        &__input {
            & + label {
                &:before {
                    width: 17px;
                    height: 17px;
                }
            }
        }
    }
}
 
.cancel-request,
.mentorship-welcome {
  left: 50%;
  width: 730px;
  padding-bottom: 40px;
  padding-top: 30px;
  line-height: normal;
  margin-left: -366px;
  @media (max-width: 767px) {
    width: 90%;
    right: auto;
    left: auto;
    margin-left: 5%;
  }
  .modal-body {
    font-family: 'Univers-Light', Arial, Helvetica, sans-serif;
    font-size: 18px;
    padding-right: 0;
  }
  .btn {
      margin-bottom: 15px;
      margin-left: 0;
      padding: 7px 17px !important;
  }
  .modal-footer {
    padding: 34px 15px 15px;
    .btn + .btn {
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
  }
}

.mentorship-welcome {
  padding-top: 64px;
  .modal-footer {
    padding-top: 20px;
  }
}

.legal-container {
    p {
        font-family: Arial, Helvetica, sans-serif;
        line-height: 21px;
    }
    ul {
        margin: 0 0 10.5px 25px;
        li {
            font-family: Arial, Helvetica, sans-serif;
            line-height: 21px;
            list-style-type: disc;
        }
    }
    h2,
    h3 {
        font-family: 'Univers-Regular', Arial, Sans-Serif;
    }
}

.faq-container,
.feedback-container,
.contact-container {
    h2,
    h3,
    h4,
    h5 {
        font-family: 'Univers-Regular', Arial, Sans-Serif;
    }
    h4 {
        line-height: 32px;
    }
    p {
        font-family: Arial, Helvetica, sans-serif;
        line-height: 21px;
    }
    ul {
        margin: 0 0 10.5px 25px;
        li {
            font-family: Arial, Helvetica, sans-serif;
            line-height: 21px;
            list-style-type: disc;
        }
    }
    #load_menu {
        li {
            list-style-type: none;
        }
    }
    .side-right-bar .nav-list ul > li > a {
        padding: 10px 0 10px 10px;
    }
}

#contact_form {
    margin-left: 0 !important;
}

.faq-container {
    h3 {
        margin-top: 42px;
        font-size: 26px;
    }
    h4 {
        margin-top: 42px;
    }
}

body.prelogin {
    .promo-basics {
        //position: absolute;
        //width: 100%;
        //top: 61px;
        //z-index: 99;
    }
}

.contact-container,
.feedback-container,
.advertise {
    .select2-container .select2-selection--single {
        height: 32px;
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: 30px;
    }
    .select2-container--default .select2-selection--single .select2-selection__placeholder {
        color: #b2b3b5;
        font-size: 16px;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        top: 57%;
    }
    input[type="text"],
    input[type="password"],
    input[type="email"],
    textarea,
    .select2-container {
        margin-bottom: 0;
        margin-top: 20px;
    }
    textarea#message {
        margin-top: 13px;
    }
    input.ivy-error,
    textarea.ivy-error {
        border: 1px solid $brand-red;
    }
    .select2-hidden-accessible.ivy-error + .select2-container {
        .select2-selection--single {
            border: 1px solid $brand-red;
        }
    }
    #submitButton {
        margin-top: 30px;
    }
}

.feedback-container {
    #subject {
        margin: 15px 0;
    }
    .ivy-error#subject {
        margin: 0;
    }
}

.promo-bar{
    // padding-top: 0 !important;

}

.ivy-headline.minimized {
    margin-top: 0;
}

.block.register {
    .step1, .step2 {
        h2 {
            ul {
                li {
                    font-size: 14px;
                    line-height: initial;
                    &:before {
                        content: '';
                        height: 4px;
                        width: 4px;
                        border-radius: 20px;
                        background: #F7323F;
                        display: inline-block;
                        margin-top: -3px;
                        vertical-align: middle;
                        margin-right: 9px;
                    }
                }
            }
        }
    }
}





.btn-feedback {
    background: #B2B3B2 !important;
    border: none !important;
    transform: rotate(-90deg);
    position: fixed;
    right: -30px;
    z-index: 1049;
    bottom: 350px;
    font-size: 16px !important;
}


.smcx-embed {
    width: 100% !important;
    max-width: 100% !important;
    height: 400px !important;
}

.smcx-embed>.smcx-iframe-container {
    width: 100% !important;
    max-width: 601px !important;
    height: 363px !important;
}

html.auto-scroll .modern-browser .question-row {
    margin: 0 0 100px 0 !important;
}

.survey-page.survey-page-white .survey-footer {
    margin-bottom: 0 !important;
}

#feedbackModal{
    z-index: 1000;
}

#footer > .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.btn.btn-feedback {
    display: inline-block !important;
    margin-bottom: 0 !important;
    font-weight: normal !important;
    text-align: center !important;
    vertical-align: middle !important;
    touch-action: manipulation !important;
    cursor: pointer !important;
    background-image: none !important;
    border: 1px solid transparent !important;
    white-space: nowrap !important;
    padding: 6px 12px !important;
    line-height: 1.42857 !important;
    border-radius: 1px !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    &:hover {
        box-shadow: none !important;
    }
}

.navbar-fixed-top .navbar__signin-employers .dropdown-menu li a:hover,
.navbar-fixed-top .navbar__signin-employers .dropdown-menu li a:focus,
.navbar-fixed-top .navbar__signin-employers .dropdown-menu li a:active {
    color: #000!important;
}
